import React from 'react';
import { graphql } from 'gatsby';

import Page, { PageData, PageType } from '@/components/Page';
import ArticleTagPage, {
  ArticleTagPageType,
} from '@/components/blog/ArticleTagPage';
import { ArticleBlockType } from '@/components/blog/ArticleBlock';
import { HighlightedArticleBlockType } from '@/components/blog/HighlightedArticleBlock';
import { sortArticlesByDate } from '@/utils/DateUtils';
import { createArticleTagMetaData } from '@/utils/MetaDataUtils';

type Props = PageType & {
  data: {
    contentfulPage: PageData;
    relatedArticles: {
      nodes: Array<ArticleBlockType>;
    };
    contentfulArticle: HighlightedArticleBlockType;
  };
  pageContext: {
    page: number;
    total: number;
    totalPages: number;

    tag: string;
    blogPath: string;
  };
};

const Tag: React.FC<Props> = (props) => {
  const tagPage: ArticleTagPageType = {
    page: props.pageContext.page,
    total: props.pageContext.total,
    totalPages: props.pageContext.totalPages,
    articles: sortArticlesByDate(props.data.relatedArticles.nodes),
    title: props.pageContext.tag,
    blogPath: props.pageContext.blogPath,
  };
  createArticleTagMetaData(tagPage.title, props.data.contentfulPage);
  return (
    <Page {...props}>
      <ArticleTagPage {...tagPage} />
    </Page>
  );
};

export default Tag;

export const pageQuery = graphql`
  query articleTagQuery(
    $contentfulId: String!
    $locale: String
    $articles: [String]
  ) {
    contentfulPage(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $locale }
    ) {
      node_locale
      ...PagePathFragment

      seoMetadata {
        ...SeoMetaDataFragment
      }
      pageType {
        ... on ContentfulBlogPageType {
          ...BlogFragment
        }
      }
    }
    allLanguages: allContentfulPage(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      nodes {
        node_locale
        ...PagePathFragment
      }
    }
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    relatedArticles: allContentfulArticle(
      filter: { contentful_id: { in: $articles }, node_locale: { eq: $locale } }
    ) {
      nodes {
        ...ArticleBlockFragment
      }
    }
  }
`;
