import React from 'react';
import styled from 'styled-components';
import slugify from 'slugify';
import { Pagination } from '@keytrade/components-pagination';
import { useLocalization } from '@plugins/keytrade-localization/useLocalization';
import { ArticleBlockType } from '@/components/blog/ArticleBlock';
import ArticlesBlock from '@/components/blog/ArticlesBlock';
import Title from '@/components/Title';
import Container from '@/components/Container';
import { getBackgroundColor } from '@/utils/StyleUtils';
import { mediaQuery } from '@/utils/helpers';
import useTranslations from '@/hooks/useTranslations';
import '@/components/Page';

import InternalLink from '../InternalLink';
import { LinkStyle } from '../Link';
import './Author';

export type ArticleTagPageType = {
  title: string;
  page: number;
  total: number;
  totalPages: number;
  articles: Array<ArticleBlockType>;
  blogPath: string;
};
const BlogContent = styled.section`
  padding-top: 10.6rem;
  padding-bottom: 11.2rem;
  position: relative;
  &:before {
    content: '';
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 24.2rem;
    z-index: -1;
    background: ${getBackgroundColor('Light blue')};
  }
  ${mediaQuery.md(`
  padding-top: 18.6rem;
  &:before {
    height: 34.2rem;
  }
  `)}
`;
type Props = ArticleTagPageType;
const ArticleTagPage: React.FC<Props> = ({
  title,
  articles,
  page,
  totalPages,
  blogPath,
}) => {
  const { t } = useTranslations();
  const { navigate } = useLocalization();
  const getArticleTagPath = (ArticleTag: string) => {
    return `/${slugify(ArticleTag, { lower: true, strict: true })}/`;
  };
  const ArticleTagPath = getArticleTagPath(title);
  const onPageClick = (pageNr) => {
    const currentPage = pageNr === 1 ? '' : `${pageNr}`;
    navigate(`${blogPath}${ArticleTagPath}${currentPage}`);
  };

  articles.forEach((article) => {
    article.blogPath = blogPath;
  });
  return (
    <BlogContent>
      <Container narrow={true}>
        <nav>
          <InternalLink linkStyle={LinkStyle.BACK} to={blogPath}>
            {t('back_to_blog')}
          </InternalLink>
        </nav>
        <Title size='xxl' level='h1' margin='3.2rem 0 8rem'>
          {title}
        </Title>
        <ArticlesBlock articles={articles} />
        <Pagination
          numberPages={totalPages}
          selectedPage={page}
          onClick={onPageClick}
          transparentBackground={false}
          prevAccessibilityLabel={t('previous')}
          nextAccessibilityLabel={t('next')}
          margin={'7.2rem 0 0 0'}
        />
      </Container>
    </BlogContent>
  );
};

export default ArticleTagPage;
